import React from 'react';

import { LazyIcon } from 'components/LazyIcon/LazyIcon';

import {
  QuickLinks,
  QuickLink,
} from '@johnlewispartnership/wtr-ingredients/ingredients/QuickLinks';

import { useWtrSelector } from 'redux/hooks';
import { getGLPNav } from 'redux/modules/experiments/selectors';
import { hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import { getEditSlotUrl } from 'redux/modules/slot-booking/selectors/get-edit-slot-url';

import styles from './GLPNav.scss';

export interface menuItem {
  icon: string;
  name: string;
  url: string;
}

const GLPNav = () => {
  const menus = useWtrSelector(getGLPNav);
  const isSlotBooked = useWtrSelector(hasBookedSlot);
  const changeSlotUrl = useWtrSelector(getEditSlotUrl);

  const slotExists = (name: string) => name === 'Book a slot' && isSlotBooked;

  if (!menus?.length) return null;

  return (
    <div className="container-fluid">
      <QuickLinks className={styles.glpNavContainer}>
        {menus.map((item: menuItem) => (
          <QuickLink
            href={slotExists(item.name) ? changeSlotUrl : item.url}
            icon={
              <LazyIcon
                name={slotExists(item.name) ? 'CalendarBooked' : item.icon}
                size="large"
                className={item.name === 'Offers' ? styles.offers : undefined}
              />
            }
            label={slotExists(item.name) ? 'Change slot' : item.name}
          />
        ))}
      </QuickLinks>
    </div>
  );
};

GLPNav.displayName = 'HeaderLogo';

export default GLPNav;
